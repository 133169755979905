.channel-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 12px;
  padding-bottom: 0;
}

.channel-header__icon-and-title {
  align-items: center;
  display: flex;
}

.channel-header__icon {
  background-color: #eee;
  background-position: center;
  background-size: cover;
  border-radius: 40px;
  cursor: pointer;
  height: 72px;
  position: relative;
  width: 72px;
}

.channel-header__icon-upload-button {
  background-color: rgba(255, 255, 255, 0.5) !important;
  border-radius: 4px;
  bottom: 0;
  position: absolute;
  right: 0;
}

.channel-header__icon-upload-button:hover {
  cursor: pointer;
}

.channel-header__file-input {
  width: 0;
}

.channel-header__title {
  cursor: pointer;
  font-size: 24px;
  margin-left: 16px;
}

.channel-header__title-editing {
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: text;
  padding: 4px;
}

@media (max-width: 599px) {
  .channel-header__icon {
    height: 56px;
    width: 56px;
  }

  .channel-header__title {
    font-size: 18px;
    line-height: 26px;
    margin-left: 12px;
    margin-right: 12px;
  }
}

.channel-header__action-buttons {
  display: flex;
}

.channel-header__left-action-button {
  margin-right: 16px;
}

.channel-header__subscribe-popout {
  padding: 14px;
}

.channel-header__subscribe-popout-text {
  font-size: 18px;
  margin-bottom: 8px;
}

.channel-header__subscribe-popout-sign-in-button {
  margin-top: 12px;
  width: 100%;
}

.channel-header__details {
  font-size: 16px;
  line-height: 24px;
  padding: 12px;
  padding-bottom: 0;
}

.channel-header__links {
  margin-top: 12px;
}

a.channel-header__link {
  border: none !important;
  color: var(--mdc-theme-secondary) !important;
}

a.channel-header__link:hover {
  text-decoration: underline;
}
