.def-tooltip {
  pointer-events: none;
  position: absolute;
  z-index: 9999999;
}

.def-tooltip__content {
  max-width: 220px;
  padding-top: 12px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
}

.def-tooltip__def {
  margin-bottom: 12px;
}

.def-tooltip__pron {
  color: #bdbdbd;
  font-size: 16px;
  margin-bottom: 4px;
  text-align: center;
}

.def-tooltip__meaning {
  font-size: 16px;
  text-align: center;
}

.def-tooltip__level-container {
  margin-bottom: 16px;
  margin-top: -8px;
}

.def-tooltip__level {
  display: inline-block;
  border-bottom-width: 2px;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px;
  margin-right: 4px;
  margin-top: 8px;
}

.rmwc-tooltip {
  z-index: 1000000;
}
