:root {
  --exercise-separator: 1px dotted rgba(0, 0, 0, 0.15);
}

.exercises-section__start-button {
  font-size: 18px;
  min-height: 48px;
  width: 100%;
}

.exercise {
  align-items: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000000;
}

.exercise-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  padding-bottom: 25vh;
}

.exercise__fill-blanks-container,
.exercise__top-choice-container {
  border-top: var(--exercise-separator);
  padding-top: 12px;
  text-align: center;
  width: 100%;
}

.exercise__prompt {
  font-size: 26px;
  margin: 24px;
  text-align: center;
}

.exercise__translation {
  border-top: var(--exercise-separator);
  box-sizing: border-box;
  color: #616161;
  font-size: 20px;
  margin-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 12px;
  text-align: center;
  width: 100%;
}

.exercise__top-choice-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.exercise__bottom-choice-container {
  border-top: var(--exercise-separator);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
  padding-top: 12px;
  width: 100%;
}

.exercise__choice {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 20px;
  margin: 8px;
  min-width: 64px;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}

/* prevent vocab bolding */
.exercise__choice .word__text {
  font-weight: 400 !important;
}

.exercise__choice:hover {
  border-color: var(--mdc-theme-secondary) !important;
  cursor: pointer;
}

.exercise__choice--audio {
  cursor: pointer !important;
  display: flex;
  justify-content: center;
  padding: 12px;
  pointer-events: all !important;
}

.exercise__choice--complete.exercise__choice--audio {
  pointer-events: none !important;
}

.exercise__choice--selected {
  background-color: #bbdefb;
  border-color: var(--mdc-theme-secondary) !important;
  color: var(--mdc-theme-secondary) !important;
  pointer-events: none;
}

.exercise__choice--selected .word__pron,
.exercise__choice--selected .word__text {
  color: var(--mdc-theme-secondary) !important;
}

.exercise__choice .word__container {
  border: none !important;
  margin: 0;
  pointer-events: none;
}

.exercise__choice .mdc-button__label,
.exercise__choice--selected .mdc-button__label {
  font-weight: 300 !important;
  letter-spacing: normal;
}

.exercise__choice--meaning {
  font-size: 16px !important;
  font-weight: 300 !important;
  letter-spacing: normal;
}

.exercise__choice--blank {
  display: inline-block;
  margin: 4px;
  min-width: 40px;
}

.exercise__choice--complete {
  opacity: 0.4;
  pointer-events: none;
}

.exercise__choice--highlighted {
  border-color: var(--mdc-theme-secondary) !important;
}

.exercise__choice--highlighted .word__pron,
.exercise__choice--highlighted .word__text {
  color: var(--mdc-theme-secondary) !important;
}

.exercise__correct-panel {
  background-color: #e3f2fd;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  bottom: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  font-size: 22px;
  left: 0;
  padding: 16px;
  position: fixed;
  width: 100vw;
}

.exercise__done-button {
  display: block;
  font-size: 18px;
  margin-top: 16px;
  min-height: 48px;
}

/* bottom choice styling */

.exercise__bottom-choice-container .exercise__choice {
  background-color: var(--mdc-theme-secondary);
  border: none;
  color: white;
}

.exercise__bottom-choice-container .word__pron,
.exercise__bottom-choice-container .word__text {
  color: white;
}
