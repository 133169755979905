:root {
  --draft-sidebar-width: 360px;
}

.draft {
  align-items: stretch;
  display: flex;
}

.draft__doc-container {
  display: flex;
  justify-content: center;
  width: calc(100vw - var(--draft-sidebar-width));
}

/* common components */

.toggle-button--on {
  color: var(--mdc-theme-secondary);
}

.toggle-button--off {
  color: black;
}

/* create dialog */

.create-draft-dialog__file {
  display: block;
  margin-top: 16px;
}

.create-draft-dialog__text {
  margin-top: 16px;
  min-height: 400px;
  min-width: 400px;
}

/* block editor */

.draft-top-bar__top-row {
  align-items: center;
  display: flex;
  padding-left: 8px;
  padding-right: 8px;
}

.draft-top-bar__bottom-row {
  align-self: center;
  padding-bottom: 4px;
}

.edit-modes {
  display: flex;
  justify-content: center;
  pointer-events: all;
  width: 100%;
}

.edit-modes__mode-button--selected {
  color: var(--mdc-theme-secondary) !important;
  cursor: default;
  pointer-events: none;
}

/* top bar right side action menu */

.draft-action-menu__menu-item {
  width: 140px;
}

/* make contenteditable work in safari */
[contenteditable='true'] {
  -webkit-user-select: text;
  user-select: text;
}

[contenteditable='true']:empty:before {
  content: attr(placeholder);
  display: block;
  color: #aaa;
}

/* role editor */

.role-editor {
  margin-top: -4px;
}

.role-editor .mdc-chip-set {
  justify-content: center;
}

.role-editor .mdc-chip {
  border-radius: 12px;
}

.role-editor__icon {
  background-color: transparent;
  border: 1px solid #cccccc;
}

.role-editor__icon .mdc-chip__icon--leading {
  margin: -4px !important;
}

.mdc-chip__text__primary-action {
  outline: none;
}

.role-editor__current-role-row {
  align-items: center;
  background-color: white;
  border-radius: 4px;
  height: 32px;
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
  position: relative;
}

.role-editor__name {
  font-size: 16px;
  line-height: 32px;
  min-height: 32px;
  position: absolute;
  width: calc(100%);
}

.role-editor .editable {
  border: 1px solid #cccccc;
  box-sizing: border-box;
  min-height: 32px;
  padding-left: 4px;
  padding-right: 4px;
  width: 100%;
}

.role-editor__current-role-buttons {
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  height: 100%;
  line-height: 0;
  position: absolute;
  right: 0;
}

#editable {
  min-height: 34px;
  min-width: 100px;
  padding: 8px;
}

/* insert bubbles */

#draft-bubbles-dialog__track-select {
  font-size: 18px;
  margin-bottom: 16px;
  padding: 6px;
  width: 100%;
}

.draft-bubbles-dialog__row--selected {
  color: black;
}

.draft-bubbles-dialog__row--notSelected {
  color: #bdbdbd;
}

/* timing */

.draft-block-timing {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: -4px;
  padding-bottom: 8px;
  padding-top: 8px;
}

/* word sync */

.word-sync-tool__message {
  margin: 16px;
}

/* old editor */

#draft-doc-type {
  align-items: center;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 600px) {
  #draft-doc-type {
    height: calc(0.5625 * (600px - (16px * 2)));
    width: calc(600px - (16px * 2));
  }
}

@media (max-width: 599px) {
  #draft-doc-type {
    height: calc(0.5625 * 100vw);
    width: 100vw;
  }
}

#draft-doc-type__inner {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.draft-doc-type__radio {
  background-color: white;
  border-radius: 8px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  margin-bottom: 8px;
  margin-right: 16px;
  padding-left: 8px;
  padding-right: 16px;
}

/* draft-image */

.draft-image__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
}

#draft-image__search-bar {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: row;
  height: 52px;
  justify-content: center;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.draft-image__progress {
  bottom: 0;
  opacity: 0.7;
  position: absolute;
}

.draft-image__actions {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 52px;
  justify-content: center;
  width: 100%;
}

/* draft-template */

#draft-template {
  border: 1px dashed gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 16px;
  padding: 16px;
}

#draft-template__select-button {
  color: white !important;
}

#draft-template__select-languages {
  margin-top: 16px;
  font-size: 18px;
  text-align: center;
}

#draft-media__change-url {
  align-self: flex-end;
  color: var(--mdc-theme-primary);
  cursor: pointer;
  font-size: 12px;
  line-height: 20px;
  margin-right: 16px;
  text-decoration: underline;
}

#draft-footer {
  border: 1px dashed gray;
  margin-bottom: 64px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 64px;
  padding: 16px;
}

#draft-footer__footer-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#draft-footer__publish-button {
  margin-right: 16px;
}

.draft-footer__version {
  font-size: 16px;
  margin-top: 16px;
  text-align: center;
}

.draft-footer__view-version-button {
  margin-left: 8px;
}

#draft-footer__error-message {
  color: var(--mdc-theme-error);
  font-size: 16px;
  margin-top: 16px;
  text-align: center;
}

.draft-footer__progress {
  margin-top: 16px;
}

.editor-description__language {
  color: var(--mdc-theme-primary);
  cursor: pointer;
  text-decoration: underline;
}

#translation-editable {
  border: 1px solid rgba(0, 0, 0, 0.4);
  margin: -1px;
  min-height: 22px;
}

#editor-translation-links__rows {
  max-height: 70vh;
}

.editor-translation-links__row {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.editor-translation-links__input {
  margin: 8px;
  margin-left: 16px;
  width: 100px;
}

.draft-keywords {
  border: 1px dashed gray;
  margin: 16px;
  padding: 16px;
}

.draft-keywords__label {
  margin-right: 16px;
}

.draft-keywords__keyword {
  background-color: #eee;
  border-radius: 4px;
  font-size: 18px;
  margin-right: 16px;
  padding: 4px;
}

.draft-keywords__keyword:hover {
  color: red;
  cursor: pointer;
}

.draft-keywords__input {
  border: none;
  border-bottom: 1px solid black;
  font-size: 18px;
  width: 120px;
}

.language-select {
  position: relative;
  display: inline-block;
}

.language-select__anchor {
  position: absolute;
  /*display: inline;*/
  /*min-width: 320px;*/
  white-space: nowrap;
}

.Editor-Block-disabled {
  cursor: default;
  opacity: 0.5;
  user-select: none;
}

.Editor-mode-container {
  align-items: stretch;
  background-color: #eee;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  left: calc(50vw - 568px);
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 32px;
  position: fixed;
  top: 64px;
  user-select: none;
  width: 240px;
  z-index: 1;
}

.EditorFocusIndicator {
  background-color: #2196f3;
  left: calc((100vw - 600px) / 2 - 28px);
  position: absolute;
  transform: var(--editor-transform) translateY(64px);
  width: 8px;
}

.EditorFocusIndicator-scrollIntoView {
  height: calc(337.5px + 64px + 48px + 32px + 8px + 100%);
  margin-top: calc(-337.5px + -64px + -48px + -32px);
}

/* media */

figure {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-left: 0;
  margin-right: 0;
}

.media-editor-sticky-container {
  pointer-events: none;
  width: 600px;
}

/* bubbles */

.insert-bubbles-dialog__cell {
  padding: 0;
}

.bubble-editor {
  position: relative !important;
}

.bubble-bubble-editor {
  cursor: text !important;
}

.bubble-bubble-editor-withTimeAndFocus {
  min-height: 90px;
}

.editor-block-recording {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.EditorBlockTime {
  align-items: center;
  box-sizing: border-box;
  color: black;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
  font-size: 18px;
  justify-content: flex-start;
  margin-left: -288px;
  padding-left: 4px;
  position: absolute;
  width: 240px;
  user-select: none;
}

.EditorBlockTime-row {
  align-items: center;
  display: flex;
  height: 38px;
  width: 200px;
}

.EditorBlockTime-spacer {
  width: 40px;
}

.EditorBlockTime-time {
  font-size: 18px;
  line-height: 18px;
}

.EditorBlockTime-Icon {
  margin-right: 8px;
}

.EditorBlockTime-IconButton {
  margin-left: -8px;
  margin-right: -8px;
}

.EditorBlockTime-arrow {
  margin: -16px;
  transform: scale(0.5);
}

.Editorbubble-clickOverlay {
  cursor: pointer;
  height: 100%;
  left: 0;
  margin-left: -8px;
  margin-top: -8px;
  padding: 8px;
  position: absolute;
  right: 0;
}

.Editorbubble-clickOverlay:hover {
  background-color: rgba(0, 0, 0, 0.025);
}

.bubble-translation-editor {
  font-family: 'Nunito', sans-serif;
  min-width: 200px;
}

.bubble-translation-editor:empty:before {
  color: #aaa;
  content: attr(placeholder);
}

.Editor-loading-label {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 12px;
  text-align: center;
}

/* roles */

.role-draft__menu-surface {
  overflow: visible;
}

.role-draft__use-voice-container {
  background-color: #f5f5f5;
  border-radius: 8px;
  margin-top: 12px;
  padding: 10px;
}

#role-draft__use-voice-checkbox {
  margin-right: 8px;
}

.role-draft__use-voice-select {
  margin: 4px;
  margin-top: 8px;
}

.RoleEditor {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  font-family: 'Roboto', sans-serif;
  padding: 16px;
  width: 300px;
}

.RoleEditor-name {
  box-sizing: border-box;
  font-size: 16px;
  padding: 4px;
  width: 100%;
}

.RoleEditor-align-container {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  user-select: none;
}

.RoleEditor-label {
  background-color: #eee;
  border-radius: 8px;
  cursor: pointer;
  flex: 1;
  font-size: 15px;
  margin: 2px;
  padding: 6px;
  padding-left: 8px;
  padding-right: 8px;
  text-transform: capitalize;
}

.role-draft__radio {
  margin-right: 8px;
}

.RoleEditor-color-container {
  display: flex;
  margin-top: 12px;
  user-select: none;
}

.RoleEditor-color-column {
  align-items: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  user-select: none;
}

.RoleEditor-submit {
  margin-top: 12px;
  width: 100%;
}

/* popups */

.EditorTextMode-language-container {
  margin-top: 24px;
}

.EditorTextMode-language-label {
  font-family: sans-serif;
  font-size: 16px;
  margin-bottom: 8px;
}

.LanguageSelect-button {
  width: 100%;
}

/* override default button styling */
.byline-editor__button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: 'Nunito', sans-serif;
  font-size: 18px;
  padding: 0;
  text-decoration: underline;
}

.draft-word,
.draft-word--with-link {
  border-bottom: solid 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  box-shadow: 0 1px rgba(0, 0, 0, 0.2);
}

.draft-word:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.draft-word--with-link {
  border-bottom: solid 4px rgba(0, 0, 0, 0.9);
}

/* draft word levels */

.draft-word--level-unknown {
  border-bottom: dashed 4px rgba(255, 255, 255, 1);
}

.draft-word--level-1 {
  border-bottom: dashed 4px rgba(33, 150, 243, 1);
}

.draft-word--level-2 {
  border-bottom: dashed 4px rgba(76, 175, 80, 1);
}

.draft-word--level-3 {
  border-bottom: dashed 4px rgba(255, 193, 7, 1);
}

.draft-word--level-4 {
  border-bottom: dashed 4px rgba(244, 67, 54, 1);
}

.draft-word--level-5 {
  border-bottom: dashed 4px rgba(156, 39, 176, 1);
}

.draft-word--level-6 {
  border-bottom: dashed 4px rgba(0, 0, 0, 1);
}

/* draft word with link levels */

.draft-word--with-link-level-unknown {
  border-bottom: solid 4px white;
}

.draft-word--with-link-level-1 {
  border-bottom: solid 4px #2196f3;
}

.draft-word--with-link-level-2 {
  border-bottom: solid 4px #4caf50;
}

.draft-word--with-link-level-3 {
  border-bottom: solid 4px #ffc107;
}

.draft-word--with-link-level-4 {
  border-bottom: solid 4px #f44336;
}

.draft-word--with-link-level-5 {
  border-bottom: solid 4px #9c27b0;
}

.draft-word--with-link-level-6 {
  border-bottom: solid 4px black;
}

.draft-selection__tooltip {
  z-index: 10000;
}

.rmwc-tooltip-inner {
  padding: 0;
}

.draft-tooltip-link {
  align-items: center;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 4px;
  padding-bottom: 12px;
  padding-top: 8px;
}

.draft-tooltip-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.draft-tooltip-link__pron {
  font-size: 16px;
  text-align: center;
}

.draft-tooltip-link__meaning {
  font-size: 16px;
  text-align: center;
}

.draft-tooltip-link__grammar {
  display: flex;
}

.draft-tooltip-link__thumbnail {
  background-position: center;
  background-size: cover;
  height: 45px;
  width: 80px;
}

.draft-tooltip-link__title {
  font-size: 16px;
  margin-left: 12px;
}

.draft-link-tooltip--selection {
  z-index: 99999;
}

.draft-link-tooltip__vocab-container {
  cursor: pointer;
  text-align: center;
}

.draft-link-tooltip__vocab-container:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.draft-link-tooltip__button-row {
  align-items: center;
  display: flex;
  margin-bottom: 8px;
}

.draft-link-tooltip__button {
  align-items: center;
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  color: white !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
  padding-top: 8px;
  width: 100%;
}

.draft-link-tooltip__button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.link-editor-dialog__input-container {
  margin-bottom: 16px;
  margin-top: 2px !important;
  position: relative;
}

.link-editor-dialog__input {
  display: block;
  border: none;
  font-family: 'Nunito';
  font-size: 18px;
  padding-bottom: 4px;
  padding-top: 4px;
  width: 100%;
}

.link-editor-dialog__translate-icon {
  position: absolute;
  right: 0;
  top: 4px;
}

.block__translate-icon {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 2px;
}

.draft-word-picker__word,
.draft-word-picker__word--selected {
  display: inline-block;
  cursor: pointer;
}

.draft-word-picker__word--selected {
  background-color: rgba(0, 0, 0, 0.87);
  border-radius: 4px;
  color: white;
}

.draft-selection__selection {
  pointer-events: none;
  position: absolute;
}
