.media-editor {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.media-editor__content {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}

.media-editor__radio {
  margin: 8px;
  padding-right: 8px;
}

.media-editor__actions {
  align-items: center;
  bottom: -28px;
  display: flex;
  flex-shrink: 0;
  height: 28px;
  justify-content: flex-end;
  pointer-events: none;
  position: absolute;
  width: 100%;
}

.media-editor__edit-icon {
  background-color: #eee;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 28px;
  padding: 2px;
  pointer-events: all;
  margin: 0;
  width: 28px;
}

.media-editor__youtube-url {
  left: 16px;
  position: absolute;
  top: 16px;
  width: calc(100% - 40px);
  z-index: 1;
}

.media-editor__progress {
  bottom: -4px;
  position: absolute;
}
