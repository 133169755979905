.auth__text {
  margin-bottom: 20px;
}

.auth__action-button {
  margin-bottom: 12px;
  width: 100%;
}

.auth__input {
  display: block;
  margin-bottom: 12px;
}

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--mdc-theme-secondary);
}

.auth__error {
  color: var(--red);
  margin-bottom: 12px;
}

.auth__third-party {
  display: flex;
  justify-content: center;
  margin-bottom: 4px;
  width: 100%;
}

.auth__google {
  cursor: pointer;
  height: 46px;
  margin-bottom: 12px;
  width: 191px;
}

.auth__google:hover {
  opacity: 0.85;
}

.auth__connecting {
  display: flex;
  justify-content: center;
}
