.preview-toolbar__slug-input {
  border: 1px solid rgba(0, 0, 0, 0.2);
  margin-right: 16px;
  min-width: 200px;
  padding: 4px;
}

.preview-toolbar__unlisted-checkbox {
  width: 100%;
}
