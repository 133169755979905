/* common components */

:root {
  --content-font-family: 'Nunito', sans-serif;
  --media-height: calc(0.5625 * (600px - (16px * 2)));
  --media-max-height: 288px;
  --media-top: 64px;
  --media-width: calc(600px - (12px * 2));
  --bottom-bar-height: 72px;
}

@media (max-width: 599px) {
  :root {
    --media-height: calc(0.5625 * 100vw);
    --media-top: 56px;
    --media-width: 100vw;
  }
}

@media (max-height: 800px) {
  :root {
    --media-max-height: 36vh;
  }
}

#rmwcPortal {
  z-index: 100000;
}

.mdc-top-bar__title,
.mdc-drawer__title,
.mdc-tab__text-label,
.mdc-button__label,
.mdc-list-item {
  font-weight: bold !important;
}

.rmwc-tooltip {
  opacity: 1;
}

.rmwc-tooltip-inner {
  background-color: black;
}

.hidden {
  position: absolute;
  visibility: hidden;
}

.spacer {
  pointer-events: none;
  visibility: hidden;
}

.icon--clickable,
.icon--clickable-on {
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  margin-left: 7px;
  margin-right: 7px;
}

.icon--clickable:hover {
  background-color: #eee;
}

.icon--clickable-on {
  color: var(--mdc-theme-secondary);
}

.icon--disabled {
  cursor: default;
  opacity: 0.4;
}

.icon-button--on {
  color: var(--mdc-theme-secondary);
}

#action-bar__menu-button {
  color: black;
}

.action-bar__icon-button--disabled {
  opacity: 0.5;
}

.doc-header-tabs__fixed-adjust {
  padding-top: 48px;
}

.action-bar__message {
  margin-right: 24px;
}

.action-bar__button {
  margin-right: 8px;
}

#action-bar__progress {
  margin-left: 12px;
  margin-right: 12px;
}

.error {
  color: #b00020;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  width: 100%;
  word-break: break-all;
}

/* disable number input spinner */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

/* drawer */

.drawer__header {
  margin-bottom: -16px;
}

/* drafts */

#drafts__create-button {
  margin: 16px;
}

/* body */

.body {
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  max-width: 600px;
  width: 100%;
}

.body__header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.body__section {
  padding-bottom: 16px;
  padding-top: 16px;
}

.body__section--row {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 599px) {
  .body__header {
    margin-left: 16px;
    margin-right: 16px;
  }

  .body__section {
    margin-left: 16px;
    margin-right: 16px;
  }
}

.body__tab-bar {
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
}
