/* word */

.segment-editor {
  position: relative;
}

.segment-editor__row {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.segment-editor__word {
  color: var(--mdc-theme-secondary);
  font-size: 26px;
}

.segment-editor__edit-save-buttons {
  align-items: center;
  display: flex;
}

.segment-editor__save-button {
  margin-right: 16px;
}

/* def */

.segment-editor__def {
  align-items: center;
  background-color: black;
  border: 8px solid transparent;
  border-radius: 4px;
  color: white;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding: 4px;
  padding-bottom: 12px;
  position: relative;
}

.segment-editor__delete-def-button {
  bottom: 0;
  left: 0;
  position: absolute;
}

.segment-editor__move-def-up-button {
  bottom: 0;
  position: absolute;
  right: 32px;
}

.segment-editor__duplicate-button {
  bottom: 0;
  position: absolute;
  right: 0;
}

.segment-editor__add-def-button {
  margin-top: 16px;
  width: 100%;
}

/* pron */

.segment-editor__pron-list {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.segment-editor__pron-group {
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  margin-bottom: 4px;
}

.segment-editor__pron-group--selected {
  background-color: var(--mdc-theme-secondary) !important;
}

.segment-editor__pron {
  border: 1px solid transparent;
  display: inline-block;
  font-size: 16px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 4px;
  vertical-align: middle;
}

.segment-editor__pron--small {
  font-size: 14px;
}

.segment-editor__meaning {
  border: 1px solid transparent;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  padding: 4px;
  text-align: center;
}

.segment-editor__meaning--selected {
  background-color: var(--mdc-theme-secondary) !important;
}

.segment-editor__pron-group:hover,
.segment-editor__meaning:hover {
  background-color: #212121;
}

/* common */

.segment-editor__input {
  border: 1px solid rgba(255, 255, 255, 0.35);
  cursor: text;
}

.segment-editor__icon-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  cursor: pointer;
}
