:root {
  --feature-width: 45%;
  --home-width: 600px;
  --home-vertical-margin: 40px;
}

@media (max-width: 600px) {
  :root {
    --feature-width: 100%;
  }
}

.home {
  align-self: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 96px;
  max-width: 100vw;
  width: var(--home-width);
}

.home__loading {
  color: #676767;
  margin-bottom: 64px;
  margin-top: 16px;
  text-align: center;
}

.home__lesson-list {
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 12px;
  width: 100%;
}

.home__text {
  font-size: 17px;
  line-height: 28px;
  margin-bottom: var(--home-vertical-margin);
  padding-left: 12px;
  padding-right: 12px;
}

.home__email {
  color: var(--blue);
}

.home__heading {
  font-size: 24px;
  margin-bottom: 20px;
  padding-left: 12px;
  padding-right: 12px;
}

.home__feature-list {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.home__feature {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--home-vertical-margin);
  width: var(--feature-width);
}

.home__feature-image {
  max-width: 260px;
  opacity: 0.6;
}

.home__feature-text {
  font-size: 17px;
  line-height: 24px;
  margin-top: 12px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}

.home__examples-button {
  font-size: 16px;
  height: 56px;
  margin-bottom: var(--home-vertical-margin);
  width: 80%;
}
