:root {
  --red: #f44336;
  --red-faint: #ef9a9a;
  --red-light: #ffebee;
  --red-dark: #b71c1c;

  --pink: #e91e63;
  --pink-faint: #f48fb1;
  --pink-light: #fce4ec;
  --pink-dark: #880e4f;

  --purple: #9c27b0;
  --purple-faint: #ce93d8;
  --purple-light: #f3e5f5;
  --purple-dark: #4a148c;

  --deepPurple: #673ab7;
  --deepPurple-faint: #b39ddb;
  --deepPurple-light: #ede7f6;
  --deepPurple-dark: #311b92;

  --indigo: #3f51b5;
  --indigo-faint: #9fa8da;
  --indigo-light: #e8eaf6;
  --indigo-dark: #1a237e;

  --blue: #2196f3;
  --blue-faint: #90caf9;
  --blue-light: #e3f2fd;
  --blue-dark: #0d47a1;

  --lightBlue: #03a9f4;
  --lightBlue-faint: #81d4fa;
  --lightBlue-light: #e1f5fe;
  --lightBlue-dark: #01579b;

  --cyan: #00bcd4;
  --cyan-faint: #80deea;
  --cyan-light: #e0f7fa;
  --cyan-dark: #006064;

  --teal: #009688;
  --teal-faint: #80cbc4;
  --teal-light: #e0f2f1;
  --teal-dark: #004d40;

  --green: #4caf50;
  --green-faint: #a5d6a7;
  --green-light: #e8f5e9;
  --green-dark: #1b5e20;

  --lightGreen: #8bc34a;
  --lightGreen-faint: #c5e1a5;
  --lightGreen-light: #f1f8e9;
  --lightGreen-dark: #33691e;

  --lime: #c0ca33;
  --lime-faint: #e6ee9c;
  --lime-light: #f0f4c3;
  --lime-dark: #827717;

  --yellow: #fdd835;
  --yellow-faint: #fff59d;
  --yellow-light: #fff9c4;
  --yellow-dark: #f57f17;

  --amber: #ffc107;
  --amber-faint: #ffe082;
  --amber-light: #fff8e1;
  --amber-dark: #ff6f00;

  --orange: #ff9800;
  --orange-faint: #ffcc80;
  --orange-light: #fff3e0;
  --orange-dark: #e65100;

  --deepOrange: #ff5722;
  --deepOrange-faint: #ffab91;
  --deepOrange-light: #fbe9e7;
  --deepOrange-dark: #bf360c;

  --grey: #bdbdbd;
  --grey-faint: #e0e0e0;
  --grey-light: #eee;
  --grey-dark: #212121;
}

.color-red {
  color: var(--red-dark);
}
.bg-red {
  background-color: var(--red-light);
}
.hover-bg-red:hover {
  background-color: var(--red-dark) !important;
}

.color-pink {
  color: var(--pink-dark);
}
.bg-pink {
  background-color: var(--pink-light);
}
.hover-bg-pink:hover {
  background-color: var(--pink-dark) !important;
}

.color-purple {
  color: var(--purple-dark);
}
.bg-purple {
  background-color: var(--purple-light);
}
.hover-bg-purple:hover {
  background-color: var(--purple-dark) !important;
}

.color-deepPurple {
  color: var(--deepPurple-dark);
}
.bg-deepPurple {
  background-color: var(--deepPurple-light);
}
.hover-bg-deepPurple:hover {
  background-color: var(--deepPurple-dark) !important;
}

.color-indigo {
  color: var(--indigo-dark);
}
.bg-indigo {
  background-color: var(--indigo-light);
}
.hover-bg-indigo:hover {
  background-color: var(--indigo-dark) !important;
}

.color-blue {
  color: var(--blue-dark);
}
.bg-blue {
  background-color: var(--blue-light);
}
.hover-bg-blue:hover {
  background-color: var(--blue-dark) !important;
}

.color-lightBlue {
  color: var(--lightBlue-dark);
}
.bg-lightBlue {
  background-color: var(--lightBlue-light);
}
.hover-bg-lightBlue:hover {
  background-color: var(--lightBlue-dark) !important;
}

.color-cyan {
  color: var(--cyan-dark);
}
.bg-cyan {
  background-color: var(--cyan-light);
}
.hover-bg-cyan:hover {
  background-color: var(--cyan-dark) !important;
}

.color-teal {
  color: var(--teal-dark);
}
.bg-teal {
  background-color: var(--teal-light);
}
.hover-bg-teal:hover {
  background-color: var(--teal-dark) !important;
}

.color-green {
  color: var(--green-dark);
}
.bg-green {
  background-color: var(--green-light);
}
.hover-bg-green:hover {
  background-color: var(--green-dark) !important;
}

.color-lightGreen {
  color: var(--lightGreen-dark);
}
.bg-lightGreen {
  background-color: var(--lightGreen-light);
}
.hover-bg-lightGreen:hover {
  background-color: var(--lightGreen-dark) !important;
}

.color-lime {
  color: var(--lime-dark);
}
.bg-lime {
  background-color: var(--lime-light);
}
.hover-bg-lime:hover {
  background-color: var(--lime-dark) !important;
}

.color-yellow {
  color: var(--yellow-dark);
}
.bg-yellow {
  background-color: var(--yellow-light);
}
.hover-bg-yellow:hover {
  background-color: var(--yellow-dark) !important;
}

.color-amber {
  color: var(--amber-dark);
}
.bg-amber {
  background-color: var(--amber-light);
}
.hover-bg-amber:hover {
  background-color: var(--amber-dark) !important;
}

.color-orange {
  color: var(--orange-dark);
}
.bg-orange {
  background-color: var(--orange-light);
}
.hover-bg-orange:hover {
  background-color: var(--orange-dark) !important;
}

.color-deepOrange {
  color: var(--deepOrange-dark);
}
.bg-deepOrange {
  background-color: var(--deepOrange-light);
}
.hover-bg-deepOrange:hover {
  background-color: var(--deepOrange-dark) !important;
}

.color-grey {
  color: var(--grey-dark);
}
.bg-grey {
  background-color: var(--grey-light);
}
.hover-bg-grey:hover {
  background-color: var(--grey-dark) !important;
}
