.channel {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  overflow-x: hidden;
  width: 100vw;
}

/* banner */

.channel__banner {
  background-color: #eee;
  background-position: center;
  background-size: cover;
  height: 17vw;
  min-height: 100px;
  position: relative;
  width: 100vw;
}

.channel__banner-upload-button {
  background-color: rgba(255, 255, 255, 0.5) !important;
  bottom: 8px;
  position: absolute;
  right: 8px;
}

.channel__file-input {
  width: 0;
}

/* tabs */

.channel__tab-bar {
  box-sizing: border-box;
  margin-top: 16px;
}
