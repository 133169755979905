/* key vocabulary */

.vocab-table {
  border-collapse: collapse;
  border-spacing: 0 !important;
  box-sizing: border-box;
  line-height: 22px;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 8px;
  width: calc(100% - 32px);
}

.vocab-table__row {
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.vocab-table__row:hover {
  background-color: #eee !important;
}

.vocab-table__word {
  font-size: 20px;
  padding-bottom: 8px;
  padding-right: 16px;
  padding-top: 8px;
}

.vocab-table__pron {
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
}

.vocab-table__pron--small {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}

.vocab-table__meaning {
  font-size: 16px;
  padding: 10px;
  padding-left: 0;
  max-width: calc((100vw - 32px) * 2 / 3);
}

@media (min-width: 600px) {
  .vocab-table__meaning {
    max-width: 300px;
  }
}

.vocab-table__level {
  color: rgba(0, 0, 0, 0.5);
  display: inline-block;
  border-bottom-width: 2px;
  font-size: 14px;
  line-height: 16px;
}
