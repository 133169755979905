:root {
  --tile-width: calc((600px - 12px * 3) / 2);
}

@media (max-width: 600px) {
  :root {
    --tile-width: calc(100vw - 12px * 2);
  }
}

.doc-link-tile {
  margin-bottom: 24px;
  width: var(--tile-width);
}

.doc-link-tile__poster {
  background-size: cover;
  background-position: center;
  cursor: pointer;
  height: calc(var(--tile-width) * 9 / 16);
  width: var(--tile-width);
}

.doc-link-tile__info {
  display: flex;
  margin-top: 10px;
}

.doc-link-tile__channel-icon {
  border-radius: 18px;
  height: 36px;
  width: 36px;
}

.doc-link-tile__info-text {
  font-size: 15px;
  margin-left: 10px;
}

.doc-link-tile__title {
  color: var(--mdc-theme-text-primary-on-background);
  cursor: pointer;
  display: block;
  font-weight: 800;
  padding-bottom: 3px;
  text-decoration: none;
}

.doc-link-tile__channel-title {
  color: var(--mdc-theme-text-secondary-on-background);
  display: block;
  text-decoration: none;
}

.doc-link-tile__details {
  color: var(--mdc-theme-text-secondary-on-background);
  display: block;
  text-decoration: none;
}
