/* page */

body {
  font-family: 'Nunito', 'Noto Sans TC', sans-serif;
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
  overflow-x: hidden;

  /* disable iOS double-tap-to-zoom */
  /* https://stackoverflow.com/questions/46167604/ios-html-disable-double-tap-to-zoom */
  touch-action: manipulation !important;

  user-select: none;
  -webkit-user-select: none;

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

#root {
  align-items: center;
  display: flex;
  flex-direction: column;
}

::selection {
  background-color: var(--lightBlue-dark);
  color: white;
}

/* text links */

@keyframes linkHoverAnimation {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 500px 0;
  }
}

.doc a {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;
}

.doc a:hover {
  color: #1976d2;
  opacity: 1 !important;
}

.doc a:hover .word__pron {
  color: #1976d2;
}

/* doc error boundary */

.doc-error-boundary {
  align-items: center;
  background-color: var(--red-light);
  box-sizing: border-box;
  display: flex;
  height: var(--media-height);
  justify-content: center;
  margin-bottom: 200px;
  margin-left: auto;
  margin-right: auto;
  width: var(--media-width);
}

.doc-error-boundary__icon {
  color: var(--red);
}

/* doc */

.doc {
  background-color: white;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  font-size: 22px;
  font-weight: 400;
  line-height: 34px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 200px;
  position: relative;
}

@media (min-width: 600px) {
  .doc {
    width: 600px;
  }
}

@media (max-width: 599px) {
  .doc {
    width: 100vw;
  }
}

/* lesson links */

.link-outer {
  margin-left: 12px;
  margin-right: 12px;
}

.link,
.link-selected {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-top: 16px;
  user-select: none;
}

.link:hover {
  background-color: #eee;
}

.link-selected {
  background-color: #ddd;
}

.link__thumbnail {
  background-position: center;
  background-size: cover;
  height: 90px;
  min-width: 160px;
  width: 160px;
}

.link__text {
  font-size: 15px;
  line-height: 20px;
  margin-left: 12px;
  width: calc(100% - 100px - 32px);
  overflow: hidden;
}

.link__primary_text {
  color: var(--mdc-theme-text-primary-on-background);
  font-weight: 800;
  overflow: hidden;
  padding-bottom: 2px;
}

.link__secondary_text {
  color: var(--mdc-theme-text-secondary-on-background);
}

/* doc info */

.doc-info {
  font-size: 16px;
  line-height: 26px;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 4px;
}

.doc-info__text {
  margin-right: 16px;
}

.doc-info__source-icon {
  margin-right: 8px;
  vertical-align: middle;
}

.doc-info__target-icon {
  margin-left: 16px;
  margin-right: 8px;
  vertical-align: middle;
}

.doc-info__vocabulary {
  margin-top: 8px;
}

.doc-info__vocabulary-icon {
  margin-right: 8px;
  vertical-align: middle;
}

.doc-info__language {
  cursor: pointer;
  text-decoration: underline;
}

.doc-info__checkbox {
  font-size: 16px;
  margin-right: 16px;
  text-transform: capitalize;
}

.doc-info__checkbox .mdc-checkbox,
.doc-info__checkbox .mdc-radio {
  margin-left: -11px;
}

.doc-info__link {
  border: none !important;
  cursor: pointer;
  text-decoration: underline !important;
}

.doc-info__link:hover {
  background-color: transparent !important;
  color: black !important;
}

/* text */

#doc__title {
  align-self: stretch;
  border: none;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 20px;
}

@media (min-width: 600px) {
  #doc__title {
    font-size: 36px;
    line-height: 48px;
  }
}

@media (max-width: 599px) {
  #doc__title {
    font-size: 32px;
    line-height: 42px;
  }
}

.multiple-choice-reading-outer {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 24px;
}

.choices {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
  min-height: 48px;
}

.choices .mdc-button__label {
  font-weight: normal !important;
}

.choices__choice-row {
  align-items: center;
  display: flex;
  margin-bottom: 12px;
}

.choices__choice--disabled,
.choices__choice--correct,
.choices__choice--correct-answered,
.choices__choice--incorrect,
.choices__choice--incorrect-answered {
  background-color: var(--mdc-theme-secondary);
  border-radius: 4px;
  color: white !important;
  flex-grow: 1;
  font-size: 18px;
  height: auto;
  letter-spacing: normal;
  margin-left: 4px;
  margin-right: 4px;
  padding: 4px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  text-transform: none;
}

.choices__choice--disabled {
  background-color: var(--grey) !important;
}

.choices__choice--correct-answered {
  background-color: var(--green) !important;
}

.choices__choice--incorrect:not(:active) {
  transition: background-color 500ms step-end;
}

.choices__choice--incorrect:active {
  background-color: red !important;
}

.choices__choice--incorrect-answered {
  background-color: var(--grey) !important;
}

.choices__add-button-icon {
  opacity: 0.5;
}

.choices__add-button {
  border: 1px dashed var(--grey);
  flex-grow: 1;
}

.word,
.word-with-grammar,
.word-with-grammar--highlighted,
.word--listening-mode {
  display: inline-block;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.word-with-grammar--highlighted {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  color: white;
  cursor: pointer;
}

.word-with-grammar:hover {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  color: white;
  cursor: pointer;
}

.block-text--annotating {
  user-select: text;
}

.word__container {
  margin-bottom: 3px;
  margin-right: 2px;
  position: relative;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
}

.word__container--no-chars {
  border-bottom-color: rgba(0, 0, 0, 0.2);
  border-bottom-style: dotted !important;
  border-bottom-width: 2px !important;
}

.word__container--chars {
  border-bottom-width: 0 !important;
}

.word__container--level-0 {
  border-bottom-color: #90caf9 !important;
  border-bottom-style: solid !important;
}

.word__container--level-1 {
  border-bottom-color: #a5d6a7 !important;
  border-bottom-style: solid !important;
}

.word__container--level-2 {
  border-bottom-color: #ffe082 !important;
  border-bottom-style: solid !important;
}

.word__container--level-3 {
  border-bottom-color: #ffab91 !important;
  border-bottom-style: solid !important;
}

.word__container--level-4 {
  border-bottom-color: #f48fb1 !important;
  border-bottom-style: solid !important;
}

.word__container--level-5 {
  border-bottom-color: #b39ddb !important;
  border-bottom-style: solid !important;
}

.word__container--level-6 {
  border-bottom-color: #bdbdbd !important;
  border-bottom-style: solid !important;
}

.word__container:hover,
.word__container:hover .word__pron {
  color: white !important;
}

.word__container:hover,
.word__container:hover .word__pron {
  background-color: var(--mdc-theme-primary);
  border-radius: 4px;
}

.block-text__segment--vocab {
  font-weight: 500;
}

.block-text__segment--highlighted,
.block-text__segment--highlighted .word__pron {
  background-color: var(--mdc-theme-secondary) !important;
  color: white !important;
}

.word__annotation-segment {
  border-bottom-color: rgba(0, 0, 0, 0.2);
  border-bottom-style: dotted !important;
  cursor: text !important;
  margin-right: 2px;
}

.word__annotation-segment:hover {
  background-color: transparent !important;
  color: var(--mdc-theme-secondary) !important;
}

.word__annotation-segment--vocab {
  border-bottom-width: 2px !important;
  font-weight: 500;
}

.word__annotation-segment--non-vocab {
  border-bottom-width: 2px !important;
}

.word__pron {
  color: rgba(0, 0, 0, 0.5);
  font-size: 15px;
  line-height: 15px;
  padding: 1px;
  padding-top: 4px;
  text-align: center;
}

.word__pron--small {
  font-size: 12px;
}

.word__text {
  letter-spacing: 1px;
  text-align: center;
  user-select: text;
}

.word__text:hover {
  text-decoration: none;
}

.word__chars {
  display: flex;
  justify-content: center;
  pointer-events: none;
}

.word__char {
  border-bottom-color: rgba(0, 0, 0, 0.2);
  border-bottom-style: dotted;
  border-bottom-width: 2px;
  margin-right: 2px;
}

.word:hover {
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 4px;
  color: white;
}

.word--playing {
  animation: animatedBackground 30s linear infinite forwards;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='38' viewBox='0 0 10 38' preserveAspectRatio='none slice'%3E%3Ccircle cx='2' cy='29' r='2' fill='white'/%3E%3C/svg%3E");
  border-bottom: 4px solid rgba(0, 0, 0, 0);
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
}

.listening-mode__choices {
  margin-bottom: 2px;
  margin-top: 2px;
}

.listening-mode__choice,
.listening-mode__choice--hidden {
  cursor: pointer;
  display: inline-block;
  margin: 2px;
  padding: 2px;
}

.listening-mode__choice--hidden {
  opacity: 0;
  pointer-events: none;
}

.listening-mode__choice:hover {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}

.word--listening-mode {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  color: transparent;
}

.word--listening-mode:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.listening-mode__blank {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  color: transparent;
  display: inline-block;
  margin-bottom: 3px;
  margin-right: 3px;
  width: 1.5em;
}

/* tooltip-link */

.tooltip-link {
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  max-width: 220px;
  padding: 12px;
  padding-left: 16px;
  padding-right: 16px;
}

.tooltip-link__pron {
  font-size: 16px;
  font-style: italic;
  margin-bottom: 4px;
  text-align: center;
}

.tooltip-link__meaning {
  font-size: 16px;
  text-align: center;
}

/* questions */

.block__number {
  margin-right: 16px;
}

.block__letter {
  margin-right: 16px;
  margin-top: 6px;
}

.block__question-row,
.block__question-row--right {
  align-items: center;
  display: flex;
  width: 100%;
}

.block__question-row--right {
  justify-content: flex-end;
}

.choices__checkbox {
  margin-right: 8px;
}

.block__radio {
  margin-right: 8px;
}

.bubble-container {
  background-color: #eee;
  border-radius: 12px;
}

.bubble-extension {
  border-top: 1px solid rgba(255, 255, 255, 0.7);
  background-color: rgba(255, 255, 255, 0.4);
  /* padding-bottom: 4px;
  padding-top: 4px; */
}

/* media */

.media {
  background-color: #eee;
  height: var(--media-height);
  margin-left: auto;
  margin-right: auto;
  max-height: var(--media-max-height);
  pointer-events: all;
  top: var(--media-top);
  width: var(--media-width);
  z-index: 1;
}

.media--small {
  height: 200px;
}

.media--sticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
}

.video-player {
  align-items: flex-end;
  background-color: black;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.video-player audio {
  width: 100%;
}

/* auto scroller */

.auto-scroller {
  pointer-events: none;
  position: absolute;
}

.auto-scroller__highlight-left,
.auto-scroller__highlight-right {
  background-color: var(--mdc-theme-secondary);
  height: 48px;
  position: absolute;
  width: 8px;
}

.auto-scroller__highlight-left {
  left: 0;
  top: calc(50% - 24px + 16px);
}

.auto-scroller__highlight-right {
  right: 0;
  top: calc(50% - 24px + 16px);
}

.auto-scroller__highlight-left--editing,
.auto-scroller__highlight-right--editing {
  background-color: var(--mdc-theme-secondary);
  height: 100%;
  position: absolute;
  width: 8px;
}

.auto-scroller__highlight-left--editing {
  left: 0;
}

.auto-scroller__highlight-right--editing {
  right: 0;
  top: 0;
}

/* .auto-scroller__scroll-into-view {
  background-color: pink;
  width: 8px;
  z-index: 100;
} */

/* readings */

.reading-paragraph {
  background-color: #eee;
  margin-top: 16px;
  padding: 16px;
}

@media (min-width: 600px) {
  .reading-paragraph {
    margin-left: 16px;
    margin-right: 16px;
  }
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 250px 0;
  }
}

.reading-paragraph__sentence--playing {
  animation: animatedBackground 60s linear infinite forwards;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='38' viewBox='0 0 10 38' preserveAspectRatio='none slice'%3E%3Ccircle cx='2' cy='29' r='2' fill='rgba(0,0,0,0.25)'/%3E%3C/svg%3E");
  border-bottom: 4px solid rgba(0, 0, 0, 0);
}

.reading__play-button-container {
  display: inline-block;
  height: 20px;
  margin-left: 4px;
  position: relative;
  width: 32px;
}

.reading__play-button {
  color: rgba(0, 0, 0, 0.8);
  height: 26px;
  padding: 1px;
  position: absolute;
  width: 26px;
}

/* block error boundary */

.block-error-boundary {
  background-color: var(--red-light);
  border-radius: 12px;
  line-height: 0;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
  padding: 16px;
  text-align: center;
}

.block-error-boundary__icon {
  color: var(--red);
}

/* bubbles */

.bubble__play-button {
  color: rgba(0, 0, 0, 0.8);
  height: 48px;
  padding: 1px;
  margin-bottom: -8px;
  margin-left: -12px;
  margin-right: 4px;
  margin-top: -8px;
  min-width: 48px;
  width: 48px;
}

.bubble__play-button.mdc-icon-button::before {
  background-color: transparent !important;
}

.bubble-inline-block,
.bubble-inline-block--blurred {
  display: inline-block;
  min-height: 27px;
}

.bubble-inline-block--blurred {
  color: transparent;
  text-shadow: 0 0 12px rgba(0, 0, 0, 0.5);
}

@keyframes bubblePlaying {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 1000px 0;
  }
}

@media (min-width: 600px) {
  .bubble-bubble {
    max-width: calc(600px - 80px);
  }
}

@media (max-width: 599px) {
  .bubble-bubble {
    max-width: calc(100vw - 80px);
  }
}

.role__spacer {
  height: 8px;
}

.bubble-role {
  font-size: 16px;
  line-height: 24px;
  margin-right: 4px;
  margin-top: 4px;
  user-select: none;
  white-space: nowrap;
}

.bubble-role-right {
  align-self: flex-end;
  margin-left: 4px;
  margin-right: 0 !important;
  text-align: right;
}

.bubble-notes {
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
  line-height: 26px;
  margin-top: 2px;
  user-select: none;
  width: 100%;
}

.bubble-notes--right {
  text-align: right;
}

/* bubble styles */

.right {
  align-self: flex-end;
}

.noColor {
  background-color: #f5f5f5;
  color: #212121;
}
