.draft-formatting-menu {
  align-items: center;
  display: flex;
  justify-content: center;
}

.draft-formatting-menu__tooltip .rmwc-tooltip-inner {
  padding: 0 !important;
}

#draft-formatting-menu__anchor {
  position: absolute;
}

@media (min-width: 600px) {
  #draft-formatting-menu__anchor {
    transform: translateY(64px);
  }
}

@media (max-width: 599px) {
  #draft-formatting-menu__anchor {
    transform: translateY(56px);
  }
}

.draft-formatting-menu__button,
.draft-formatting-menu__button--selected {
  justify-content: flex-start !important;
  height: 48px !important;
}

.draft-formatting-menu__button--selected {
  color: #2196f3 !important;
}
