:root {
  --waveform-scale-factor: 4;
}

.time-shifter {
  background-color: white;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  height: calc(100vh - 64px);
  /* position: relative; */
  overflow: hidden;
  width: 120px;
}

.time-shifter::-webkit-scrollbar {
  display: none;
}

.sync-toolbar__row {
  align-items: center;
  display: flex;
  justify-content: center;
}

.time-shifter__center-line,
.time-shifter__center-line--no-time {
  top: calc((100vh - 64px) / 2);
  height: 1px;
  position: absolute;
  width: 120px;
  z-index: 1;
}

.time-shifter__center-line {
  background-color: var(--blue);
}

.time-shifter__center-line--no-time {
  background-color: var(--red);
}

.time-shifter__overlay {
  background-color: #2196f3;
  width: 100%;
  top: 50%;
  opacity: 0.3;
  pointer-events: none;
  position: fixed;
}

.time-shifter__scroll-container {
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  width: 100%;
}

.waveform__uplot {
  top: 0;
  left: 0;
  position: absolute;
  transform: translate(
      124px,
      calc((100vh / 2) - (257.7px * var(--waveform-scale-factor)))
    )
    rotate(90deg) scale(var(--waveform-scale-factor), 1);
  transform-origin: 0 0;
}

.waveform__uplot .u-over,
.waveform__uplot .u-under {
  height: 0 !important;
  visibility: hidden;
  width: 0 !important;
}

.timeline {
  background-position: top 5px right 0;
  background-repeat: repeat-y;
  position: relative;
}

.timeline__second-label {
  border-top: 1px solid #e0e0e0;
  font-size: 14px;
  margin-top: calc((100vh / 2) - 31px);
  padding-right: 4px;
  position: absolute;
  right: 0;
  text-align: right;
  width: 40px;
}
