.doc-settings__section {
  margin-bottom: 8px;
  margin-top: 8px;
}

.doc-settings__section-title {
  font-size: 16px;
}

.doc-settings__checkbox {
  font-size: 15px;
  text-transform: capitalize;
}

.doc-settings__checkbox .mdc-checkbox,
.doc-settings__checkbox .mdc-radio {
  margin-left: 4px;
}
