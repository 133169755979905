.block {
  position: relative;
}

.block--clickable {
  cursor: pointer;
  /* https://stackoverflow.com/a/29961714 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.block--clickable:hover::before {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.4);
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 8px;
  width: 100%;
}

.block--editing::after {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
  content: '';
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 8px;
  width: 100%;
}

.block__checkbox {
  position: absolute;
  right: -48px;
  top: 32px;
}

.block__audio-icon {
  left: -48px;
  position: absolute;
  top: 32px;
}

.heading-outer {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 28px;
}

.heading-inner {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  font-size: 28px;
  line-height: 42px;
  padding-bottom: 4px;
}

.paragraph-outer {
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 24px;
}

.bubble-outer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
}

.bubble-bubble {
  align-items: center;
  background-color: #eee;
  border-radius: 12px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 16px;
  padding-top: 8px;
  position: relative;
}

.bubble-translation,
.reading-translation {
  background-color: transparent;
  border: none;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  line-height: 28px;
  margin-top: 4px;
  min-width: 48px;
  position: relative;
  user-select: none;
  width: 100%;
}

.space-delimited {
  font-size: 20px;
  letter-spacing: normal !important;
}
