.segment--no-def {
  user-select: text;
}

.segment--playing,
.segment--playing .word__pron {
  color: var(--mdc-theme-secondary) !important;
}

.segment--segmenting {
  cursor: text !important;
  margin-right: 12px !important;
}

a.segment--warning,
span.segment--warning {
  color: var(--orange) !important;
}

a.segment--error,
span.segment--error {
  color: var(--red) !important;
}
