.draft-sidebar {
  background-color: #eee;
  box-shadow: 0px 0px 4px rgb(0, 0, 0, 0.4);
  min-height: calc(100vh - 64px);
  width: var(--draft-sidebar-width);
}

.draft-sidebar__fixed {
  display: flex;
  height: calc(100vh - 64px);
  overflow-y: scroll;
  position: fixed;
  width: var(--draft-sidebar-width);
}

.draft-sidebar__modes {
  flex-grow: 1;
}

.edit-mode-button:hover {
  background-color: #e0e0e0;
  cursor: pointer;
}

.edit-mode-button,
.edit-mode-button--selected {
  display: flex;
  padding: 16px;
}

.edit-mode-button--selected {
  background-color: white;
}

.edit-mode-button__text {
  font-size: 22px;
  margin-left: 16px;
}

.edit-mode-container {
  background-color: white;
  padding: 16px;
  padding-top: 0;
}

.sync-toolbar-column {
  z-index: 1;
}
