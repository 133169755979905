.block-audio-recorder {
  padding-bottom: 8px;
}

.block-audio-recorder__section {
  background-color: #eeeeee;
  margin-top: 8px;
  padding-bottom: 4px;
  padding-top: 4px;
}

.block-audio-recorder__section-label {
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase;
}

.block-audio-recorder__section-row {
  display: flex;
  justify-content: center;
}

.audio-waveform__container {
  align-items: center;
  display: flex;
}

.audio-waveform__waveform {
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  box-sizing: border-box;
  height: 36px;
  overflow: hidden;
  position: relative;
  width: 360px;
}

.audio-dialog .mdc-dialog__surface {
  overflow: visible;
}

.audio-dialog__content {
  overflow: visible;
}

.audio-recorder__section {
  margin-top: 8px;
}

.audio-synthesizer__select .mdc-select__anchor {
  margin-bottom: 16px;
  width: 100%;
}

.audio-synthesizer__progress {
  margin-bottom: 12px;
  margin-top: 12px;
}

.audio-recorder {
  align-items: center;
  display: flex;
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
  }
}

.audio-recorder__recording {
  animation: fadeIn 0.375s infinite alternate;
  background-color: white;
  color: #b71c1c;
  font-size: 18px;
  left: 0;
  line-height: 36px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 360px;
  z-index: 1000;
}
