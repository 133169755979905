.reference-def {
  background-color: #eee;
  color: black;
  user-select: text;
}

.reference-def:hover {
  background-color: #eee !important;
}

.reference-def__pron-group:hover {
  background-color: transparent;
  cursor: text;
}

.reference-def__meaning:hover {
  background-color: transparent;
  cursor: text;
}
