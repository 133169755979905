.top-bar {
  background-color: white;
  color: var(--mdc-theme-text-primary-on-background);
  z-index: 100000;
}

.top-bar--lesson {
  background-color: #002a4a;
  color: white;
}

.top-bar__sign-in--lesson {
  color: white !important;
}

.mdc-top-app-bar__title {
  padding-left: 8px;
}

@media (max-width: 599px) {
  .mdc-top-app-bar__title {
    padding-left: 4px;
  }
}

#top-bar__nav-button {
  color: black;
}

.top-bar__progress {
  left: 0;
  position: fixed;
  width: 100vw;
  z-index: 10;
}

@media (min-width: 600px) {
  .top-bar__progress {
    top: 64px;
  }
}

@media (max-width: 599px) {
  .top-bar__progress {
    top: 56px;
  }
}

.top-bar__saving-icon {
  margin-right: 8px;
  opacity: 0.4;
}
