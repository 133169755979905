/* bottom bar */

.bottom-bar {
  align-items: center;
  background-color: #f5f5f5;
  bottom: 0;
  box-shadow: 0px 0px 4px rgb(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 5;
}

@media (min-width: 600px) {
  .bottom-bar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
  }
}

.bottom-bar-translation {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  line-height: 28px;
  max-width: 600px;
  padding: 16px;
  padding-bottom: 8px;
  padding-top: 8px;
}

.bottom-bar-watch {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  height: var(--bottom-bar-height);
  justify-content: space-between;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: 4px;
  padding-right: 4px;
  width: var(--media-width);
}

.bottom-bar-watch__playback-controls {
  align-items: center;
  display: flex;
  flex-grow: 1;
  font-size: 20px;
  justify-content: center;
}

.bottom-bar-watch__play-button {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  cursor: pointer;
  flex-grow: 1;
  margin-left: 4px;
  margin-right: 4px;
  padding-bottom: 4px;
  padding-top: 4px;
  text-align: center;
  width: 80px;
}

.bottom-bar-watch__play-button:hover {
  background-color: #eee;
}

.bottom-bar-watch__icon,
.bottom-bar-watch__icon-on {
  border-radius: 24px;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  padding: 8px;
  -webkit-tap-highlight-color: transparent;
}

.bottom-bar-watch__icon:hover,
.bottom-bar-watch__icon--on:hover {
  background-color: #eee;
}

.bottom-bar-watch__icon--on {
  color: var(--mdc-theme-secondary);
}

.bottom-bar-watch__icon--disabled {
  cursor: default;
  opacity: 0.4;
}

.bottom-bar-watch__icon-button,
.bottom-bar-watch__icon-button--on {
  color: rgba(0, 0, 0, 0.8);
}

.bottom-bar-watch__icon-button--on {
  color: var(--mdc-theme-secondary);
}

.bottom-bar-watch__spacer-icon {
  visibility: hidden;
}
